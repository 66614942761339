<template>
  <b-form v-if="!isUser" @submit.prevent="onSubmit" @reset.prevent="onReset">
    <h4>{{ $t('acc.hst') }}</h4>
    <b-form-row>
      <b-col>
        <b-form-group :label="$t('acc.forN', ['1'])" label-for="accTablOne" label-cols="4">
          <b-input-group>
            <template #prepend>
              <b-input-group-text>
                <b-icon-hash />
              </b-input-group-text>
            </template>
            <b-input type="number" id="accTablOne" v-model="f.forOne" :state="valInt(f.forOne)" min="0" max="1000" number />
          </b-input-group>
        </b-form-group>
      </b-col>
    </b-form-row>
    <b-form-row>
      <b-col>
        <b-form-group :label="$t('acc.forN', ['2'])" label-for="accTablTwo" label-cols="4">
          <b-input-group>
            <template #prepend>
              <b-input-group-text>
                <b-icon-hash />
              </b-input-group-text>
            </template>
            <b-input type="number" id="accTablTwo" v-model="f.forTwo" :state="valInt(f.forTwo)" min="0" max="1000" number />
          </b-input-group>
        </b-form-group>
      </b-col>
    </b-form-row>
    <b-form-row>
      <b-col>
        <b-form-group :label="$t('acc.forN', ['3'])" label-for="accTablThree" label-cols="4">
          <b-input-group>
            <template #prepend>
              <b-input-group-text>
                <b-icon-hash />
              </b-input-group-text>
            </template>
            <b-input type="number" id="accTablThree" v-model="f.forThree" :state="valInt(f.forThree)" min="0" max="1000" number />
          </b-input-group>
        </b-form-group>
      </b-col>
    </b-form-row>
    <b-form-row>
      <b-col>
        <b-form-group :label="$t('acc.forN', ['4'])" label-for="accTablFour" label-cols="4">
          <b-input-group>
            <template #prepend>
              <b-input-group-text>
                <b-icon-hash />
              </b-input-group-text>
            </template>
            <b-input type="number" id="accTablFour" v-model="f.forFour" :state="valInt(f.forFour)" min="0" max="1000" number />
          </b-input-group>
        </b-form-group>
      </b-col>
    </b-form-row>
    <b-form-row>
      <b-col>
        <b-form-group :label="$t('acc.forN', ['5'])" label-for="accTablFive" label-cols="4">
          <b-input-group>
            <template #prepend>
              <b-input-group-text>
                <b-icon-hash />
              </b-input-group-text>
            </template>
            <b-input type="number" id="accTablFive" v-model="f.forFive" :state="valInt(f.forFive)" min="0" max="1000" number />
          </b-input-group>
        </b-form-group>
      </b-col>
    </b-form-row>
    <b-form-row>
      <b-col>
        <b-form-group :label="$t('acc.forN', ['6'])" label-for="accTablSix" label-cols="4">
          <b-input-group>
            <template #prepend>
              <b-input-group-text>
                <b-icon-hash />
              </b-input-group-text>
            </template>
            <b-input type="number" id="accTablSix" v-model="f.forSix" :state="valInt(f.forSix)" min="0" max="1000" number />
          </b-input-group>
        </b-form-group>
      </b-col>
    </b-form-row>
    <b-form-row>
      <b-col>
        <b-form-group :label="$t('acc.forN', ['7'])" label-for="accTablSeven" label-cols="4">
          <b-input-group>
            <template #prepend>
              <b-input-group-text>
                <b-icon-hash />
              </b-input-group-text>
            </template>
            <b-input type="number" id="accTablSeven" v-model="f.forSeven" :state="valInt(f.forSeven)" min="0" max="1000" number />
          </b-input-group>
        </b-form-group>
      </b-col>
    </b-form-row>
    <b-form-row>
      <b-col>
        <b-form-group :label="$t('acc.forN', ['8'])" label-for="accTablEight" label-cols="4">
          <b-input-group>
            <template #prepend>
              <b-input-group-text>
                <b-icon-hash />
              </b-input-group-text>
            </template>
            <b-input type="number" id="accTablEight" v-model="f.forEight" :state="valInt(f.forEight)" min="0" max="1000" number />
          </b-input-group>
        </b-form-group>
      </b-col>
    </b-form-row>
    <b-form-row>
      <b-col>
        <b-form-group :label="$t('acc.forN', ['9'])" label-for="accTablNine" label-cols="4">
          <b-input-group>
            <template #prepend>
              <b-input-group-text>
                <b-icon-hash />
              </b-input-group-text>
            </template>
            <b-input type="number" id="accTablNine" v-model="f.forNine" :state="valInt(f.forNine)" min="0" max="1000" number />
          </b-input-group>
        </b-form-group>
      </b-col>
    </b-form-row>
    <b-form-row>
      <b-col>
        <b-form-group :label="$t('acc.forN', ['10'])" label-for="accTablTen" label-cols="4">
          <b-input-group>
            <template #prepend>
              <b-input-group-text>
                <b-icon-hash />
              </b-input-group-text>
            </template>
            <b-input type="number" id="accTablTen" v-model="f.forTen" :state="valInt(f.forTen)" min="0" max="1000" number />
          </b-input-group>
        </b-form-group>
      </b-col>
    </b-form-row>
    <b-form-row>
      <b-col>
        <b-form-group :label="$t('acc.moreN', ['6'])" label-for="accTablMore" label-cols="4">
          <b-input-group>
            <template #prepend>
              <b-input-group-text>
                <b-icon-hash />
              </b-input-group-text>
            </template>
            <b-input type="number" id="accTablMore" v-model="f.forMore" :state="valInt(f.forMore)" min="0" max="1000" number />
          </b-input-group>
        </b-form-group>
      </b-col>
    </b-form-row>
    <b-form-row class="pt-4">
      <b-col>
        <b-form-group :label="$t('acc.stot')" label-for="accTablTotal" label-cols="4">
          <b-input-group>
            <template #prepend>
              <b-input-group-text>
                <b-icon-hash />
              </b-input-group-text>
            </template>
            <b-input type="number" id="accTablTotal" v-model="f.totalSeats" :state="valInt(f.totalSeats)" min="0" max="10000" number />
          </b-input-group>
        </b-form-group>
      </b-col>
    </b-form-row>

    <h4 class="pt-3">
      {{ $t('acc.hvi') }}
    </h4>
    <b-form-row>
      <b-col>
        <b-form-group :label="$t('acc.sloc')" label-for="accTablShowRest" label-cols="11" content-cols="1">
          <b-form-checkbox id="accTablShowRest" v-model="f.showInSearch" class="float-right mb-0" size="lg" />
        </b-form-group>
        <b-form-group :label="$t('acc.stab')" label-for="accTablShowAll" label-cols="11" content-cols="1">
          <b-form-checkbox id="accTablShowAll" v-model="f.showSeats" class="float-right" size="lg" />
        </b-form-group>
      </b-col>
    </b-form-row>

    <b-form-row>
      <b-col cols="12" class="d-flex justify-content-end">
        <b-button type="reset" variant="primary" class="mr-2 mr-sm-3" id="accTablBtnReset" :disabled="!isChanged"><Icon type="r" /> {{ $t('btn.r') }}</b-button>
        <b-button type="submit" variant="primary" id="accTablBtnSubmit" :disabled="!val || !isChanged"><Icon type="u" /> {{ $t('btn.u') }}</b-button>
      </b-col>
    </b-form-row>
  </b-form>
</template>

<script>
import Icon from '@/components/Icon';
import { BIconHash } from 'bootstrap-vue';
import {REST} from '@/components/RestCall';
import { equalObjects } from '@/components/scripts/EqualObjects';

export default {
  data() {
    return {
      f: {
        uuid4: '',
        forOne: 0,
        forTwo: 0,
        forThree: 0,
        forFour: 0,
        forFive: 0,
        forSix: 0,
        forSeven: 0,
        forEight: 0,
        forNine: 0,
        forTen: 0,
        forMore: 0,
        totalSeats: 0,
        showSeats: true,
        showInSearch: true
      }
    }
  },
  computed: {
    onlineData() {
      return this.$store.state.oCustSeats;
    },
    isUser() {
      return this.$store.state.oLogin.isUser;
    },
    val() {
      return (this.valInt(this.f.forOne) && this.valInt(this.f.forTwo) && this.valInt(this.f.forThree) && this.valInt(this.f.forFour) &&
              this.valInt(this.f.forFive) && this.valInt(this.f.forSix) && this.valInt(this.f.forSeven) && this.valInt(this.f.forEight) &&
              this.valInt(this.f.forNine) && this.valInt(this.f.forTen) && this.valInt(this.f.forMore) && this.valInt(this.f.totalSeats));
    },
    isChanged() {
      return !equalObjects(this.$store.state.oCustSeats, this.f);
    },
  },
  watch: {
    onlineData: function(data) {
      this.f = { ...data };
    }
  },
  created() {
    // maybe instead of getting again, share with AccountLogin (via timestamp)
    REST.get('/customers/' + this.$store.getters.uuid + '/seats')
        .then(resp => {
          this.$store.commit('setCustSeats', resp.d.data);
        }).catch(e => {
          this.$store.commit('showWarn',e.message)
        })
  },
  methods: {
    valInt(param) {
      if (param === null || param === '') return false;
      return Number.isInteger(param) && param >=0 && param <= 1000;
    },
    onSubmit() {
      if (this.val) {
        REST.put('/customers/' + this.$store.getters.uuid + '/seats', this.f)
            .then(resp => {
              this.$store.commit('showSuccess',resp.d.message);
              this.$store.commit('setCustSeats', this.f); // triggers again computed/watch props
            }).catch(e => {
              this.$store.commit('showWarn',e.message);
            })
      } else {
        this.$router.replace('/').catch(() => {});
      }
    },
    onReset() {
      this.f = { ...this.$store.state.oCustSeats };
    }
  },
  components: {
    Icon,
    BIconHash,
  },
}
</script>
